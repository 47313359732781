import { FooterComponent } from '../../components/footer/footer.component';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySearchComponent } from '../../components/homepage/country-search/country-search.component';
import { StateSelectComponent } from '../../components/state-select/state-select.component';
import { provideNativeDateAdapter } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';

import { AwardsComponent } from '../../components/homepage/awards/awards.component';
import { CoverageComponent } from '../../components/homepage/coverage/coverage.component';
import { CovidCoverageComponent } from '../../components/homepage/covid-coverage/covid-coverage.component';
import { FaqComponent } from '../../components/homepage/faq/faq.component';
import { GetQuotePanelComponent } from '../../components/homepage/get-quote-panel/get-quote-panel.component';
import { W2cOfferingComponent } from '../../components/homepage/w2c-offering/w2c-offering.component';
import { CustomerReviewsComponent } from '../../components/homepage/customer-reviews/customer-reviews.component';
import { CommonHeaderComponent } from '../../components/common-header/common-header.component';
import { environment } from '../../../environments/environment';

import moment from 'moment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JsonDataService } from '../../services/json-data.service';
import { LocalCacheService } from '../../services/localCache.service';
import { PromotionService } from '../../services/promotion.service';
import { Subscription } from 'rxjs';
import { WordingService } from '../../services/wording.service';
import { Meta } from '@angular/platform-browser';
import { GAService } from '../../services/ga.service';
import { SegmentService } from '../../services/segment.service';
declare var $: any;
@Component({
  selector: 'app-search-country',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    FormsModule,
    CountrySearchComponent,
    StateSelectComponent,
    CalendarModule,
    OverlayPanelModule,
    TabViewModule,
    ButtonModule,
    FooterComponent,
    AwardsComponent,
    CoverageComponent,
    CovidCoverageComponent,
    FaqComponent,
    GetQuotePanelComponent,
    W2cOfferingComponent,
    CustomerReviewsComponent,
    CommonHeaderComponent,
  ],
  templateUrl: './search-country.component.html',
  styleUrl: './search-country.component.scss',
})
export class SearchCountryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  dates: moment.Moment[] = [];
  promo: string | null = null;
  partnercode: string | null = null;
  vname: string | null = null;
  affiliateList: any[] = [];
  affiliate: any;
  promoResponse: any;
  autoClub = environment.autoClub;
  titleText: string = '';
  subtitleText: string = '';
  subscriptions: Subscription = new Subscription();
  isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private jsonDataService: JsonDataService,
    private localCacheService: LocalCacheService,
    private promotionService: PromotionService,
    private wordingService: WordingService,
    private router: Router,
    private metaService: Meta,
    private gaService: GAService,
    private segmentService: SegmentService,
  ) {
    this.metaService.updateTag({
      name: 'description',
      content:
        'World2Cover is an award-winning online travel insurance provider striving to give you the best experience you could ever imagine by making our online process as seamless as possible.',
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 820;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 820;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.adjustDivHeight();
      }
    });
    this.loadWordings();
    const existingParams =
      this.localCacheService.getSessionStorage('vendorDetail') || {};
    // Subscribe to query parameters
    this.route.queryParams.subscribe((params) => {
      if (!this.isEmpty(params)) {
        const newParams = Object.fromEntries(
          Object.entries(params).map(([k, v]) => [k.toLowerCase(), v]),
        );
        this.promo = newParams['promo'];
        this.partnercode = newParams['partnercode'];
        this.vname = newParams['vname'];

        //17537 internal site promo code
        if (this.promo === 'TMMA20FAF' && this.partnercode === undefined) {
          this.checkPromoCode(this.promo);
          const quoteEnquiryObject = {
            promoCode: this.promo,
            isSingleTrip: true,
          };

          this.localCacheService.saveSessionStorage(
            'quoteEnquiry',
            quoteEnquiryObject,
          );
        } else {
          this.localCacheService.removeSessionStorage('quoteEnquiry');
        }

        //Klook switch
        const klookSwitch = false;
        if (!klookSwitch && this.partnercode === 'Klook') {
          this.partnercode = '';
        }
        if (
          !this.isEmpty(existingParams) &&
          this.partnercode === existingParams?.partnerCode
        ) {
          this.setPreviousValue(existingParams);
        } else {
          if (this.partnercode && this.vname && this.autoClub === 'W2C') {
            this.jsonDataService
              .getAffiliatesJsonData()
              .subscribe((response) => {
                this.affiliateList = response.affiliates;
                this.affiliate = this.affiliateList.find(
                  (aff) =>
                    aff.vender.toLowerCase() === this.vname?.toLowerCase(),
                );
              });

            const vendorDetailObject = {
              partnerCode: this.partnercode,
              promoCode: this.promo,
              vname: this.vname,
            };

            this.localCacheService.saveSessionStorage(
              'vendorDetail',
              vendorDetailObject,
            );

            if (this.promo) {
              this.checkPromoCode(this.promo);
              const quoteEnquiryObject = {
                promoCode: this.promo,
                isSingleTrip: true,
              };

              this.localCacheService.saveSessionStorage(
                'quoteEnquiry',
                quoteEnquiryObject,
              );
            } else {
              this.localCacheService.removeSessionStorage('quoteEnquiry');
            }
          } else {
            this.localCacheService.removeSessionStorage('vendorDetail');
          }
        }
      } else {
        if (!this.isEmpty(existingParams)) {
          this.setPreviousValue(existingParams);
        }
      }
    });
    this.gaService.sendAff_BranchEvent();
    this.segmentService.segmentAff_BranchEvent();
  }

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  setPreviousValue(existingParams: any) {
    this.promo = existingParams?.promoCode;
    this.partnercode = existingParams?.partnerCode;
    this.vname = existingParams?.vname;

    if (this.partnercode && this.vname && this.autoClub === 'W2C') {
      this.jsonDataService.getAffiliatesJsonData().subscribe((response) => {
        this.affiliateList = response.affiliates;
        this.affiliate = this.affiliateList.find(
          (aff) => aff.vender.toLowerCase() === this.vname?.toLowerCase(),
        );
      });
    }

    if (this.promo) {
      this.checkPromoCode(this.promo);
    }
  }

  ngAfterViewInit() {
    this.adjustDivHeight();
  }

  //affiliate logo set with text and height
  adjustDivHeight() {
    const divHeight = $('.main-heading').height();

    if (divHeight && $(window).width() > 700) {
      $('.quotecontain').css('margin-top', '-250px');
    }
  }

  textPaddingLogo() {
    if (
      this.partnercode === 'Austcover' ||
      this.partnercode === 'AviatorRisk' ||
      this.partnercode === 'Billowen' ||
      this.partnercode === 'Maven' ||
      this.partnercode === 'Mozo' ||
      this.partnercode === 'Remingtons' ||
      this.partnercode === 'RSIB' ||
      this.partnercode === '7Rewards' ||
      this.partnercode === 'Klook' ||
      this.partnercode === 'WSIB'
    ) {
      return 'text-logo';
    } else {
      return '';
    }
  }

  checkMobileCoBrand() {
    if (!this.isMobile) {
      if (
        this.partnercode === 'AFA' ||
        this.partnercode === 'EntertainmentBook20' ||
        this.partnercode === 'EntertainmentBook25' ||
        this.partnercode === 'Maven' ||
        this.partnercode === 'Marsh' ||
        this.partnercode === 'Regional' ||
        this.partnercode === 'RSIB' ||
        this.partnercode === 'SteadfastNSG' ||
        this.partnercode === 'Tradesure' ||
        this.partnercode === 'WSIB' ||
        this.partnercode === 'Klook' ||
        this.partnercode === 'Gallagher'
      ) {
        return 'co-brand-image-small-to-big';
      } else if (
        this.partnercode === 'Jvib' ||
        this.partnercode === 'KIE' ||
        this.partnercode === 'NTA'
      ) {
        return 'co-brand-image-big-to-small';
      } else if (
        this.partnercode === 'Canstar-PricingListing' ||
        this.partnercode === 'Canstar-SPL' ||
        this.partnercode === 'Canstar-FPL' ||
        this.partnercode === 'FIIB'
      ) {
        return 'co-brand-image-canstar';
      } else {
        return 'co-brand-image';
      }
    } else {
      if (
        this.partnercode === 'Jvib' ||
        this.partnercode === 'KIE' ||
        this.partnercode === 'JTB' ||
        this.partnercode === 'NTA' ||
        this.partnercode === 'ClickFrenzy' ||
        this.partnercode === 'Lendlease' ||
        this.partnercode === 'Tandg' ||
        this.partnercode === 'Canstar-PricingListing' ||
        this.partnercode === 'Canstar-SPL' ||
        this.partnercode === 'Canstar-FPL' ||
        this.partnercode === 'FIIB'
      ) {
        return 'co-brand-image-big';
      } else {
        return 'co-brand-image';
      }
    }
  }

  checkPromoCode(promo: any) {
    if (promo) {
      this.promotionService.validatePromoCode(promo).subscribe((promoCode) => {
        this.promoResponse = promoCode;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe;
  }

  isSelected = (event: any) => {
    const date = event as moment.Moment;
    return this.dates.find((x) => {
      return moment(x).isSame(date);
    })
      ? 'dateSelected'
      : '';
  };

  select(event: any, calendar: any) {
    const date: moment.Moment = event;
    const index = this.dates.findIndex((x) => moment(x).isSame(date));
    if (index < 0) this.dates.push(date);
    else this.dates.splice(index, 1);
    calendar.updateTodaysDate();
  }

  loadWordings() {
    const validationServiceSub = this.wordingService
      .getWordings()
      .subscribe((response) => {
        response.forEach((wording: any) => {
          if (wording.field === 'homePageTitle') {
            this.titleText = wording.value;
          }
          if (wording.field === 'homePageSubtitle') {
            this.subtitleText = wording.value;
          }
        });
      });

    this.subscriptions.add(validationServiceSub);
  }
}
